<template>
  <div class="sights-container bg-light-blue text-blue pt-5">
    <div class="container pt-5">
      <b-row>
        <b-col cols="12" md="6">
          <p class="freight-big-pro-book sub-md-text">
            SET YOUR SIGHTS ON <br />
            THE RIVER’S EDGE <br />
            COMMUNITY
          </p>
          <span class="sub-text acumin-pro-wide-medium">
            NATURE-INSPIRED. 
            MASTER-PLANNED.
          </span>
          <p class="acumin-pro-wide-light sub-text pt-2">
            Located in the renowned master-planned community of River’s Edge,
            SouthBay will leave you in awe throughout every season.
          </p>
          <p class="acumin-pro-wide-light sub-text">
            Experience the clear waters of Wasaga Beach and the untouched
            forested landscape along the Nottawasaga River, in an ambience that
            is entirely yours.
          </p>
        </b-col>
        <b-col cols="12" md="6">
          <object
            data="/img/community/SOUTHBAY_LOGO2.svg"
            type="image/svg+xml"
            class="seal-sights"
          ></object>
        </b-col>
      </b-row>
    </div>
    <b-row class="pt-5 position-relative">
      <b-col cols="9">
        <img
          src="/img/community/family.jpg"
          alt="Family running into water"
          class="img-fluid"
        />
      </b-col>
      <b-col cols="12" class="map-container mx-auto d-block">
        <img
          src="/img/community/map.jpg"
          alt="Map of Vaughan and Barrie"
          class="img-fluid"
        />
      </b-col>
    </b-row>
    <b-container>
      <b-row>
        <b-col>
          <div class="acumin-pro-wide-light sights-text">
            Within minutes, you will find schools, restaurants, shopping, and
            recreation opportunities. Connect to Collingwood, Barrie, Orillia,
            Vaughan, and beyond via GO Transit, or via Highways 10, 26 & 400.
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {
    }, 500);
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.sights-container {
  padding: 0px 0px 100px 0px;
  position: relative;
  .section-height {
    height: 100px;
  }
  .map-container {
    position: absolute;
    max-width: 420px;
    bottom: -80px;
    right: 70px;
    @media screen and (min-width: 1500px) {
      max-width: 520px;
      bottom: -80px;
      right: 250px;
    }
    @media screen and (min-width: 2500px) {
      max-width: 620px;
      bottom: -80px;
      right: 450px;
    }
    @media screen and (max-width: 1199px) {
      position: relative;
      max-width: 420px;
      padding-top: 30px;
      bottom: unset;
      right: unset;
  }
    @media screen and (max-width: 500px) {
      max-width: 300px;
      padding-top: 30px;
  }
  }
  @media screen and (max-width: 700px) {
    .seal-sights {
      max-width: 450px;
      padding-top: 20px;
    }
  }
  @media screen and (max-width: 500px) {
    .section-height {
      height: 50px;
    }
    .seal-sights {
      max-width: 300px;
      padding-top: 20px;
    }
    .sights-text {
      padding: 30px 0px 0px 0px;
    }
  }
}
</style>
